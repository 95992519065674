import React from "react"

import "./explanatoryTextGift.css";

import Icon from '../../images/top/icon_1.png';

const ExplanatoryTextGift  = () => (
  <div className="explanatory-text-gift ff-min">
    <div className="title">
      <h1>「名前」は生まれて最初にもらう最高のGIFT。</h1>
    </div>
    <p>
      あなたがあなたに託してきたこと<br />
      あなたが繋がる全ての人があなたに託した事
    </p>
    <p>
      全てが「名前」に秘められています。<br />
      お名前から人生のシナリオを読み解き、自分に惚れる人生を！
    </p>
    <p className="icon">
      <img alt="icon" src={Icon} className="scale-to-fit" />
    </p>
  </div>
)

export default ExplanatoryTextGift
