import React from "react"
import { Link } from "gatsby"

import "./categories.css";

import Slash_left from '../../images/top/slash_left.png';
import Slash_right from '../../images/top/slash_right.png';
import PointRight from '../../images/service/point_right.png'

const Categories = ({ atDetail = false }) => (
  <div className={`categories ${atDetail ? "at-detail" : ""}`}>
    <div className="outer">
      <div className="inner">
        <div className="point">
          <img alt=" " src={PointRight} className="scale-to-fit" />
        </div>
        <div className="main">
          <div className="caption">
            <div className="sub">
              <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
              <h4>
                おなまえ
                <span className="registerd-sign">&#174;</span>
                3カテゴライズ
              </h4>
              <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
            </div>
            <h3>理想の未来への法則が分かる</h3>
          </div>
          <ul className="content-wrapper">
            <li>
              <div className="head">
                <span>MISSION</span>
              </div>
              <div className="title">
                <div className="sub">
                  <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
                  <h3>才能</h3>
                  <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
                </div>
                <h2 className="ff-min">I do</h2>
              </div>
              <div className="body">
                <p>周りに与えている影響</p>
                <p>社会への貢献</p>
              </div>
            </li>
            <li>
              <div className="head">
                <span>COMMIT</span>
              </div>
              <div className="title">
                <div className="sub">
                  <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
                  <h3>本質</h3>
                  <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
                </div>
                <h2 className="ff-min">I have</h2>
              </div>
              <div className="body">
                <p>本質・価値観に影響</p>
                <p>私らしさの本来の姿</p>
              </div>
            </li>
            <li>
              <div className="head">
                <span>VISION</span>
              </div>
              <div className="title">
                <div className="sub">
                  <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
                  <h3>使命</h3>
                  <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
                </div>
                <h2 className="ff-min">I am</h2>
              </div>
              <div className="body">
                <p>生きる原動力に影響</p>
                <p>理想の未来の姿</p>
              </div>
            </li>
          </ul>
          <p className="content-wrapper read">
            「名前」はモヤモヤを解消してピッタリの叶え方・生き方を見つけてくれます。自分のポジティブもネガティブをも知って「今」から「未来」へどう行動していくか、具体的な法則を分析しましょう
          </p>
        </div>
      </div>
    </div>
    { atDetail ? 
      null
      :
      <div className="btn-holder gr">
        <Link to='/detail4'>3カテゴライズワーク詳細はこちら</Link>
      </div>
    }
  </div>
)

export default Categories
